.messages {
    width: 100%;
    background-color: #ffffff;
    margin-top: 37px;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    height: 600px;
}

.messages-left {
    width: 250px;
    height: 600px;
    border-right: 1px solid #cccccc;
    overflow-y: auto;
}

.messages-right {
    width: calc(100% - 250px);
    height: 600px;
}

.chat-element {
    flex-direction: row;
    display: flex;
    border-bottom: solid #e7e7e7;
    width: 100%;
    height: 70px;
    cursor: pointer;
}

.chat-element.active {
    background-color: rgba(19, 63, 175, .3);
}

.chat-element-left {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-element-avatar {
    width: 45px;
    height: 45px;
    border-radius: 100%;
}

.chat-element-right {
    width: calc(100% - 70px);
    padding-right: 10px;
}

.chat-element-right-top {
    display: flex;
    flex-direction: row;
    height: 20px;
    align-items: center;
    margin-top: 11px;
}

.chat-element-timestamp {
    font-size: 12px;
    margin-left: auto;
    width: 80px;
    text-align: right;
}

.chat-element-name {
    font-size: 15px;
    width: calc(100% - 80px);
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 17px;
    line-height: 17px;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
}

.chat-element-last-message {
    width: 100%;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 5px;
    font-size: 15px;
    line-height: 17px;
    height: 17px;
}

.chat-window {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 61px;
    box-sizing: border-box;
}

.chat-window-input-area {
    position: absolute;
    height: 50px;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 8px 12px;
    box-sizing: border-box;
    border-top: 1px solid #e7e7e7;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.chat-window-input-area-submit {
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 30px;
    border: 0;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}

.chat-window-input-area-submit-icon {
    width: 30px;
    height: 30px;
}

.chat-window-input-area-field {
    resize: none;
    width: calc(100% - 50px);
    height: auto;
    border: 0;
    font-size: 14px;
    font-family: 'Source Sans Pro Regular', sans-serif;
    outline: none;
}

.chat-window-messages {
    height: calc(100% - 50px);
    overflow-y: scroll;
    padding-bottom: 20px;
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
}

.chat-window-messages-wrapper {

}

.chat-window-top {
    position: absolute;
    height: 61px;
    padding: 8px;
    box-sizing: border-box;
    width: 100%;
    top: 0;
    left: 0;
    border-bottom: 1px solid #e7e7e7;
}

.chat-window-user {
    display: flex;
    flex-direction: row;
}

.chat-window-user-left {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-window-user-avatar {
    width: 45px;
    height: 45px;
    border-radius: 100%;
}

.chat-window-user-name {
    display: block;
    font-size: 14px;
    margin-top: 4px;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
}

.chat-window-user-bottom {
    display: flex;
    flex-direction: row;
    margin-top: 4px;
    align-items: center;
}

.chat-window-user-circle {
    width: 10px;
    height: 10px;
    margin-right: 3px;
}

.chat-window-user-status {
    display: block;
    font-size: 12px;
}

.chat-message {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}

.chat-message-inner {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.chat-message-avatar {
    width: 30px;
    height: 30px;
    border-radius: 30px;
}

.chat-message-bubble {
    max-width: 400px;
    background-color: #294ECE;
    border-radius: 4px;
    padding: 8px;
    box-sizing: border-box;
    margin-right: 10px;
}

.chat-message-text {
    color: #FFFFFF;
    font-size: 14px;
    display: block;
}

.chat-message-date {
    font-size: 12px;
    display: block;
    margin-top: 5px;
    color: #99AFBF;
}

.chat-message-2 {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}

.chat-message-2-inner {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.chat-message-2-avatar {
    width: 30px;
    height: 30px;
    border-radius: 30px;
}

.chat-message-2-bubble {
    max-width: 400px;
    background-color: #EDEDED;
    border-radius: 4px;
    padding: 8px;
    box-sizing: border-box;
    margin-left: 12px;
}

.chat-message-2-text {
    color: #20303C;
    font-size: 14px;
    display: block;
}

.chat-message-2-date {
    font-size: 12px;
    display: block;
    margin-top: 5px;
    color: #99AFBF;
}

@media only screen and (max-width: 790px) {

    .messages {
        height: auto;
        min-height: auto;
    }

    .messages-left {
        width: 100%;
        height: auto;
        border-right: 0;
        overflow-y: auto;
    }

    .messages-right {
        display: none;
    }

}
