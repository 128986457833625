.modal-fade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.2);
    z-index: 10000;
}

.modal-window {
    max-width: 500px;
    height: 300px;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0,0,0,.2);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12000;
    margin: auto;
}

.modal-window-top {
    height: 40px;
    width: 100%;
    background-color: rgb(26, 79, 214);
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    align-items: center;
    border-radius: 10px 10px 0 0;
}

.modal-window-top-title {
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    color: #FFFFFF;
    font-size: 18px;
}

.modal-window-top-close {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
}

.modal-window-top-close-icon {
    height: 20px;
    width: 20px;
    display: block;
}

.modal-window-top-close:hover {
    opacity: .7;
}

.modal-window-bottom {
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    align-items: center;
    border-radius: 0 0 10px 10px;
    border-top: 1px solid #e7e7e7;
}

.modal-window-content {
    height: calc(100% - 80px);
    padding: 12px 10px;
    position: relative;
    box-sizing: border-box;
}

.modal-window-content.large {
    height: 100%;
}

.modal-window-bottom-submit {
    background-color: #005BFA;
    border: 1px solid #005BFA;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 13px;
    display: flex;
    height: 20px;
    flex-direction: row;
    align-items: center;
    padding: 13px 10px;
    border-radius: 10px;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    cursor: pointer;
    margin-left: auto;
}

.modal-info-cancel {
    display: block;
    margin-top: 15px;
    color: #002b7d;
    cursor: pointer;
}

.modal-window-bottom-submit:hover, .modal-info-cancel:hover {
    opacity: .7;
}

.modal-window-bottom-left {
    color: #000;
    font-size: 14px;
    cursor: pointer;
}

.modal-window-bottom-left:hover {
    opacity: .7;
}

.modal-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.modal-info-title {
    display: block;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    font-size: 24px;
}

.modal-info-subtitle {
    display: block;
    font-size: 17px;
    text-align: center;
    margin-top: 10px;
}

.modal-info-button {
    background-color: #005BFA;
    border: 1px solid #005BFA;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    align-items: center;
    padding: 13px 17px;
    border-radius: 10px;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    cursor: pointer;
}

.modal-info-button.red {
    background-color: red;
    border: 1px solid red;
}

.modal-info-cancel-button {
    display: block;
    margin-top: 20px;
    font-size: 18px;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    color: #005BFA;
    cursor: pointer;
    outline: none;
}

.modal-info-button:hover, .modal-info-cancel-button:hover {
    opacity: .7;
}
