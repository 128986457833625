.login-page {
    background-color: #002b7d;
    width: 100%;
    min-height: calc(100vh - 86px - 150px);
}

.login-page-image {
    min-height: calc(100vh - 86px - 150px);
    width: 60%;
    background-position: center;
    background-size: cover;
}

.login-page-content {
    width: 40%;
    min-height: calc(100vh - 86px - 150px);
    box-sizing: border-box;
    padding-right: 120px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.login-page-content-inner {
    min-height: 460px;
    padding-bottom: 30px;
    padding-top: 30px;
}

.login-page-title {
    font-size: 48px;
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-family: 'Source Sans Pro Black', sans-serif;
}

.login-page-fields {
    margin-top: 30px;
}

.login-page-fields-area {
    margin-top: 15px;
}

.login-page-fields-area:first-child {
    margin-top: 0;
}

.login-page-fields-input {
    width: 100%;
    height: 30px;
    background-color: #ffffff;
    border: 2px solid rgba(0, 80, 219, 1);
    outline-color: rgba(0, 80, 219, 1);
    border-radius: 6px;
    margin-top: 5px;
    font-family: 'Source Sans Pro Regular', sans-serif;
    padding-left: 10px;
    font-size: 14px;
    padding-right: 10px;
    box-sizing: border-box;
}

.login-page-fields-input.error {
    border-color: #be0519;
}

.login-page-fields-error {
    text-align: right;
    font-size: 14px;
    color: #be0519;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    display: block;
    height: 18px;
    margin-top: 3px;
}

.login-page-fields-label {
    display: block;
    color: #ffffff;
    font-size: 17px;
}

.login-page-tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.login-page-tab {
    color: #ffffff;
    font-size: 17px;
    cursor: pointer;
}

.login-page-tab-line {
    color: #ffffff;
    font-size: 17px;
    margin-left: 6px;
    margin-right: 6px;
    font-family: 'Source Sans Pro Light', sans-serif;
}

.login-page-tab.active, .login-page-tab:hover {
    font-family: 'Source Sans Pro Bold', sans-serif;
}

.login-page-submit-area {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.login-page-submit-button {
    margin-left: auto;
    height: 30px;
    background-color: #005BFA;
    border: 1px solid #005BFA;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 13px;
    padding-right: 13px;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    cursor: pointer;
}

.login-page-submit-button:disabled {
    opacity: .7;
}

.login-page-text {
    margin-top: 20px;
    display: block;
    color: #ffffff;
    font-size: 17px;
    line-height: 22px;
    font-family: 'Source Sans Pro Light', sans-serif;
}

.login-page-text b {
    font-family: 'Source Sans Pro SemiBold', sans-serif;
}

.login-page-submit-area-back-link {
    color: #ffffff;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    font-size: 17px;
    cursor: pointer;
}

.login-page-otp-error {
    color: red;
    font-size: 17px;
    display: block;
    margin-top: 20px;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    margin-bottom: 20px;
}

.login-page-submit-button-spinner:before {
    content: '';
    box-sizing: border-box;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-top: 5px;
    margin-left: 10px;
    border-radius: 50%;
    border: 1px solid transparent;
    border-top-color: #ffffff;
    animation: spinner .6s linear infinite;
}

.login-page-bottom {
    margin-top: 160px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.login-page-forgot-password-link {
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
    font-family: 'Source Sans Pro Light', sans-serif;
}

.login-page-bottom-link {
    color: #ffffff;
    font-size: 16px;
    margin-left: auto;
}

.login-page-bottom-link a {
    color: #ffffff;
    margin-left: 5px;
    font-family: 'Source Sans Pro Light', sans-serif;
    text-decoration: underline;
    text-transform: uppercase;
}

.login-page-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 190px;
    height: 20px;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 20px;
}

.login-page-checkbox-checkmark {
    background-color: #FFFFFF;
    border: 1px solid #2C3149;
    box-sizing: border-box;
    border-radius: 2px;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-page-checkbox-checkmark-inner {
    width: 13px;
    height: 13px;
    background-color: #005BFA;
    display: none;
}

.login-page-checkbox-label {
    margin-left: 10px;
    font-size: 14px;
    line-height: 18px;
    max-width: 140px;
    color: #FFFFFF;
    user-select: none;
}

.login-page-checkbox-label a {
    color: #FFFFFF;
}

.login-page-checkbox input[type=checkbox] {
    width: 0;
    height: 0;
    opacity: 0;
}

.login-page-checkbox input[type=checkbox]:checked ~ .login-page-checkbox-checkmark .login-page-checkbox-checkmark-inner {
    display: block;
}

.login-page-accept-error {
    display: block;
    margin-top: 17px;
    font-size: 14px;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    color: red;
}

@media only screen and (max-width: 1090px) {

    .login-page-content {
        padding-right: 30px;
    }

}

@media only screen and (max-width: 890px) {

    .login-page-image {
        display: none;
    }

    .login-page-content {
        width: 100%;
    }

}

@media only screen and (max-width: 790px) {

    .login-page-title {
        font-size: 28px;
    }

    .login-page {
        min-height: calc(100vh - 50px - 70px);
    }

}
